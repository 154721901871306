<template>
  <div>
    <filter-slot
      :filter-principal="filterPrincipal"
      :filter="[]"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="$refs['gridRevisionChat'].refresh()"
    >
      <template #table>
        <b-table
          ref="gridRevisionChat"
          :items="myProvider"
          table-class="text-nowrap"
          responsive="sm"
          show-empty
          :fields="fields"
          sticky-header="70vh"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <template #cell(client_name)="data">
            <span :class="isDarkSkin ? 'text-warning' : 'text-primary'">{{
              data.value
            }}</span>
            <p class="mb-0">{{ data.item.account }}</p>
          </template>
          <template #cell(messages)="data">
            <b-button-group>
              <b-button
                variant="primary"
                size="sm"
                @click="showModal(data.item)"
              >
                <feather-icon icon="MessageSquareIcon" />
              </b-button>
            </b-button-group>
          </template>
          <template #cell(actions)="data">
            <b-button
              variant="success"
              class="ml-2 cursor-pointer"
              size="sm"
              v-if="dinamicUrl == 1"
              @click="changeStatus(1, data.item)"
            >
              <feather-icon icon="ThumbsUpIcon" />
            </b-button>
            <b-button
              variant="danger"
              class="ml-2 cursor-pointer"
              size="sm"
              v-if="dinamicUrl == 1"
              @click="changeStatus(0, data.item)"
            >
              <feather-icon icon="ThumbsDownIcon" />
            </b-button>
            <b-button
              variant="primary"
              size="sm"
              v-if="dinamicUrl != 1"
              v-b-tooltip.hover
              @click="openTracking(data.item)"
              title="Tracking"
            >
              <feather-icon icon="BookOpenIcon" />
            </b-button>
          </template>
        </b-table>
      </template>
    </filter-slot>
    <modalTracking
      v-if="modalTrackingModel"
      :modalTracking="modalTrackingModel"
      @hide="modalTrackingModel = false"
      :idCommission="item.id"
    />
    <b-modal v-model="modalModel" title="Messages" size="lg" hide-footer>
      <b-list-group>
        <b-list-group-item
          v-for="(item, index) in items.messages"
          :key="index"
          :variant="item.is_sender ? 'primary' : 'secondary'"
        >
          <!-- right text -->
          <div :style="item.is_sender ? 'float:right; text-align:right;' : ''">
            <b>
              {{ item.is_sender ? items.client_name : items.user_name }}
            </b>
            <p style="font-size: 11px" class="mb-0">
              {{ item.created_at | myGlobalDay }}
            </p>
            <p>{{ item.content }}</p>
          </div>
        </b-list-group-item>
      </b-list-group>
    </b-modal>
  </div>
</template>
<script>
import revisionService from "@/views/ce-digital/sub-modules/customer-service/views/chat-revision/services/chat-revision.service";
import { mapGetters } from "vuex";
import modalTracking from "./ModalTracking.vue";
export default {
  components: {
    modalTracking,
  },
  data() {
    return {
      paginate: {
        currentPage: 1,
        perPage: 50,
      },
      totalRows: 0,
      item: {},
      fields: [
        {
          key: "user_name",
          label: "Technical",
        },
        {
          key: "client_name",
          label: "client",
        },
        {
          key: "accept",
          label: "chat start",
        },
        {
          key: "finish",
          label: "chat end",
        },
        {
          key: "created_at",
          label: "created",
        },
        {
          key: "messages",
          label: "messages",
          class: "text-center",
        },
        {
          key: "actions",
          visible: false,
        },
      ],
      filter: [
        {
          type: "datepicker",
          margin: true,
          showLabel: true,
          label: "From",
          placeholder: "Date",
          class: "font-small-3",
          model: null,
          locale: "en",
          dateFormatOptions: {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          },
          cols: 6,
        },
      ],
      modalTrackingModel: false,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search User...",
        model: "",
      },
      startPage: null,
      toPage: null,
      isBusy: false,
      modalModel: false,
      items: [],
    };
  },
  computed: {
    dinamicUrl() {
      switch (this.$route.meta.tab) {
        case 1:
          return 1;
        case 2:
          return 2;
        case 3:
          return 3;
      }
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    async showModal(item) {
      this.addPreloader();
      if (!Boolean(item.messages?.length)) {
        const params = {
          accept_date: item.accept_date,
          finish_date: item.finish_date,
          account: item.account,
        };
        let messages = await revisionService.getCommissionMessages(params);
        item.messages = messages;
      }
      this.items = item;
      this.removePreloader();
      this.modalModel = true;
    },
    async myProvider() {
      const params = {
        status: this.dinamicUrl,
        page: this.paginate.currentPage,
        perPage: this.paginate.perPage,
        search: this.filterPrincipal.model,
        user: this.currentUser.user_id,
        role_id: this.currentUser.role_id,
      };
      let res = await revisionService.getCommissions(params);
      this.totalRows = res.total;
      this.toPage = res.to;
      this.paginate.currentPage = res.current_page;
      return res.data;
    },
    async changeStatus(status, item) {
      this.$swal({
        title: "Are you sure?",
        text: status == 0 ? "Rejected" : "Accepted",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          this.sendChangeStatus(status, item);
        }
      });
    },
    async sendChangeStatus(status, item) {
      this.addPreloader();
      const params = {
        status: status,
        user_id: this.currentUser.user_id,
        id: item.id,
        agent_id: item.agent_id,
        client_account_id: item.client_account_id,
      };
      await revisionService.changeService(params);
      this.$refs.gridRevisionChat.refresh();
      this.removePreloader();
    },
    openTracking(item) {
      this.modalTrackingModel = true;
      this.item = item;
    },
  },
  created() {
    if (this.currentUser.role_id == 16) {
      this.fields.pop();
    }
  },
};
</script>