<template>
  <b-modal
    hide-footer
    size="lg"
    sticky-header="300px"
    v-model="modalTracking"
    title="Tracking"
    centered
    @hidden="hideModal()"
  >
    <b-table striped hover :items="items">
      <template #cell(status)="data">
        <b-badge :variant="selectColor(data.item.status)" class="text-primary">
          {{ data.item.status }}
        </b-badge>
      </template>
    </b-table>
  </b-modal>
</template>
<script>
import revisionService from "@/views/ce-digital/sub-modules/customer-service/views/chat-revision/services/chat-revision.service";
import moment from "moment";
export default {
  props: {
    modalTracking: {
      type: Boolean,
      default: false,
    },
    idCommission: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      items: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    selectColor(status) {
      switch (status) {
        case "pending":
          return "light-info";
        case "desaproved":
          return "light-danger";
        default:
          return "light-success";
      }
    },
    async getData() {
      const data = await revisionService.getTracking(this.idCommission);
      data.forEach((element) => {
        element.created_at = moment(element.created_at).format("MM/DD/YYYY");
      });
      this.items = data;
    },
    hideModal() {
      this.$emit("hide");
    },
  },
};
</script>